import Legends from './assets/minecraft-legends/legends.jpg'
import LegendsRoleImage from './assets/minecraft-legends/legendsRole.jpg'
import Legends1 from './assets/minecraft-legends/legends-1.jpg'
import Legends2 from './assets/minecraft-legends/legends-2.jpg'

import Elder from './assets/the-elder/theelder.jpg'
import ElderRole from './assets/the-elder/running.jpg'
import Elder1 from './assets/the-elder/fire.jpg'
import Elder2 from './assets/the-elder/boss.jpg'
import Elder3 from './assets/the-elder/theelder_chi.jpg'

import HeatDeath from './assets/heat-death/heatdeath.jpg'
import HeatDeathRole from './assets/heat-death/heatdeath_pic1.jpg'
import HeatDeath1 from './assets/heat-death/HeatDeathPIC.jpg'
import HeatDeath2 from './assets/heat-death/bigblackhole.jpg'

import DinoBreaker from './assets/dino-breakers/dinobreaker.jpg'

import KittyPong from './assets/kitty-pong/kittypong.png'

export interface Details {
  text: string
  image?: string
}

export interface TechOverview {
  category: string
  details: string[]
}

export interface Project {
  name: string
  link: string
  image: string
  objectPosition: string
  location: string
  timeline: string
  techOverview: TechOverview[]
  roleDetails: Details
  projectDetails: Details[]
  repoLink?: string
  downloadLink?: { path: string; downloadFile: string }
  playLink?: string
  viewLink?: string
}

export const projects: Project[] = [
  {
    name: 'Minecraft Legends',
    link: 'minecraft-legends',
    image: Legends,
    objectPosition: '50% 18%',
    location: 'Blackbird Interactive',
    timeline: 'February 2020 - Present',
    techOverview: [
      {
        category: 'Languages',
        details: ['C++', 'HTML/CSS/JS']
      },
      {
        category: 'Software',
        details: ['Visual Studio', 'Perforce', 'Git Fork']
      },
      {
        category: 'Platforms',
        details: [
          'PC',
          'Steam',
          'Xbox One',
          'Xbox Series X',
          'PS4',
          'PS5',
          'Switch'
        ]
      }
    ],
    roleDetails: {
      text: `Programmer for the UI team.  Began as an Associate Programmer and was promoted to Staff Programmer in 2021.  
      Took on responsibilities of Lead Programmer for the UI team for DevOps and DLC content.`,
      image: LegendsRoleImage
    },
    projectDetails: [
      {
        text: `Minecraft Legends is a real-time action strategy game based in the Minecraft universe.  
        The game was developed using a customized version of the Bedrock engine and made available on seven different platforms simultaneously.
        The UI was created using a third party library called Gameface by Coherent, which allows you to create web based UI and bind data from the C++ engine.`,
        image: Legends1
      },
      {
        text: `The UI team was responsible for creating the UI screens in React and setting up the data binding in C++.  As the UI had to draw data from various parts of the codebase, 
        the team often ventured into uncharted territory, collaborating closely with other teams to determine the most effective approaches for accessing the required data.
        We also worked closely with a core UI team at Mojang who was responsible for creating the react-facet library, which rewires Reacts state management to be more performant.
        Additionally, we developed and tested for all seven platforms as there were different UI requirements for many of the systems.`,
        image: Legends2
      }
    ],
    viewLink: 'https://www.minecraft.net/en-us/about-legends'
  },
  {
    name: 'The Elder',
    link: 'elder',
    image: Elder,
    location: 'Vancouver Film School',
    timeline: '2019',
    objectPosition: '50% 32%',
    techOverview: [
      {
        category: 'Languages',
        details: ['C++']
      },
      {
        category: 'Software',
        details: ['Unreal Engine 4', 'Visual Studio', 'Perforce', 'Wwise']
      },
      {
        category: 'Platforms',
        details: ['Windows']
      }
    ],
    roleDetails: { text: 'Gameplay, AI & Audio Programmer', image: ElderRole },
    projectDetails: [
      {
        text: `The Elder is a 3D 3rd-person adventure platformer where you play as a young boy (Mokosh) accompanying a sapient Tree (Chi) on a journey to restart the cycle of the forest.  
        Mokosh is the more mobile and dexterous character while Chi is a ent-like mystical creature who can use fantastical abilities to help Mokosh traverse the treacherous environment.`,
        image: Elder1
      },
      {
        text: `Base Player Controller & Movement, Camera Controller, Tree Companion AI, Mechanics: Catapult & Crystal Shooting, Audio Implementation, Animation Implementation, Butterfly Flocking AI, Implemented some Shaders & Particles`,
        image: Elder2
      },
      {
        text: `For this game, the team wanted an AI Tree companion that would follow the player and act as the keeper of all the main mechanics.  
        The main challenge was designing the AI companion to be useful and consistently available to the player but never feel like he was inhibiting the player.  
        To overcome this, Chi was designed to always follow behind the player off to one side depending on the camera angle and to prioritize re-spawning out of camera view.  
        Iterating Chi's behaviors were made easier with the help of the level designer on the team, whom I worked closely with to ensure that Chi was behaving in a way that was beneficial while ensuring that he was never in the way.  
        The collaboration of our entire hardworking team made it possible to create an amazing project that we all could feel proud of.`,
        image: Elder3
      }
    ],
    repoLink: 'https://gitlab.com/allieshackelford/the-elder-full'
  },
  {
    name: 'All Hail the Heat Death',
    link: 'heat-death',
    image: HeatDeath,
    objectPosition: '98% 47%',
    location: 'Vancouver Film School',
    timeline: '2019',
    techOverview: [
      {
        category: 'Languages',
        details: ['C#']
      },
      {
        category: 'Software',
        details: ['Unity', 'Visual Studio Code', 'Perforce']
      },
      {
        category: 'Platforms',
        details: ['Windows']
      }
    ],
    roleDetails: { text: 'Gameplay & Audio Programmer', image: HeatDeathRole },
    projectDetails: [
      {
        text: `All Hail the Heat Death is a 4-player isometric black hole shooter about skeletons. Faceoff in a race against time to get the most kills and win the game.  
        Bullets that collide create black holes. Black holes that merge create bigger black holes. Once a black hole reached critical mass, the level ends!`,
        image: HeatDeath1
      },
      {
        text: `Player Controller, Shooting & Score system, Blackhole mechanic, Weapon Functionality, Animation, UI & Audio Implementation`
      },
      {
        text: `The biggest feature of this game was the black hole mechanic.  When two bullets from opposing players collide, a black hole forms and will slowly grow during the game.  
        Bullets and players that pass too closely will be affected by a gravitational pull based on the size of the black hole.  Using Unity's built-in point effector system in combination 
        with a customized black hole physics script I was able to achieve the desired effect. The other biggest feature was the scoring system.  I had no previous experience with multiplayer 
        games and it took me a while to design a scorekeeping script that could properly store the players' scores through each level and announce the winner at the end before resetting for 
        the next round.`,
        image: HeatDeath2
      }
    ],
    repoLink: 'https://gitlab.com/allieshackelford/gd54pg15_heatdeath',
    downloadLink: {
      path: 'https://gitlab.com/allieshackelford/gd54pg15_heatdeath/-/tree/master/Build/',
      downloadFile: 'Build.zip'
    }
  },
  {
    name: 'Kitty Pong',
    link: 'kitty-pong',
    image: KittyPong,
    objectPosition: '0% 0%',
    location: 'Vancouver Film School',
    timeline: '2019',
    techOverview: [
      {
        category: 'Languages',
        details: ['C++']
      },
      {
        category: 'Software',
        details: ['Visual Studio']
      },
      {
        category: 'Platforms',
        details: ['Windows']
      }
    ],
    roleDetails: { text: 'Student Programmer' },
    projectDetails: [
      {
        text: `Kitty Pong is a themed recreation of Pong in C++ using object-oriented coding practices.  Face off against someone in local multiplayer or try your hand against the AI bot!
        I took the basic Pong program that I had created during a previous assignment and componentized the entire project.  Each component can then function independently which allowed for maintainability and extendibility.
        Programming for this project was an interesting task since I was deep diving into C++ and applying object-oriented practices.  Overall, the process helped me gain a deeper understanding of game engines and the way they handle objects or components.  
        Organization was key to this project since each piece needed to be created to work independently as well as function together with all the other components. `
      }
    ],
    repoLink: 'https://gitlab.com/allieshackelford/kittypong'
  },
  {
    name: 'Dino Breakers',
    link: 'dino-breakers',
    image: DinoBreaker,
    objectPosition: '50% 57%',
    location: 'Vancouver Film School',
    timeline: '2019',
    techOverview: [
      {
        category: 'Languages',
        details: ['C#']
      },
      {
        category: 'Software',
        details: ['Unity', 'Visual Studio Code']
      },
      {
        category: 'Platforms',
        details: ['Windows']
      }
    ],
    roleDetails: { text: 'Gameplay & Audio Programmer' },
    projectDetails: [
      {
        text: `Dino Breakers is a 2D brick breaker where you must use quick thinking, power-ups and luck to destroy the brick fortress and defeat the evil dinos keeping guard.
        Dino Breakers was one of the first Unity projects that I created from beginning to end entirely on my own.  It was also one of my first experiences programming basic enemy AI which ended up being my biggest challenge.  
        I wanted the enemy dinos to patrol between two points and at certain times during the game, throw out random bad power-ups that would negatively affect the player.  
        This would offer some incentive for players to target the dinos first and they are also worth more points than the blocks.  
        However, the patrolling patterns proved to be more of a challenge than I originally expected with dinos running off into weird areas and not behaving properly.  
        In the end, I was able to fix the patrolling behavior once I simplified it and I had to cut out the behavior for throwing bad power-ups.`
      }
    ],
    repoLink: 'https://gitlab.com/allieshackelford/dinobreakers'
  }
]
