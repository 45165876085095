import React from 'react'

import PersonalPhoto from './assets/AllieShackelfordHeadshot.jpg'
import HeaderFooterWrapper from './HeaderFooterWrapper'

import './AboutPage.css'

export default function AboutPage() {
  const title = "Hi! I'm Allie"
  return (
    <HeaderFooterWrapper>
      <div className="aboutPage">
        <div className="aboutWrapper">
          <img className="personalPhoto" src={PersonalPhoto} />
          <div className="personalDetails">
            <div className="personalDetailsTitle">{title}</div>
            <div className="personalDetailsBody">
              {`I'm a software engineer with recent experience developing the UI
                for Minecraft Legends. I've worked on teams both large and small
                and I am always striving to learn and share with my teams, while
                growing as an individual.`}
              <br />
              <br />
              {`My professional experience includes working with
                large codebases, refactoring legacy code, and adding new
                features, as well as integrating third party libraries and
                collaborating with outside companies.`}
              <br />
              <br />
              {`I'm proficient in C++, Javascript(Typescript), HTML/CSS, and have some experience with
                C# and Python.`}
            </div>
          </div>
        </div>
      </div>
    </HeaderFooterWrapper>
  )
}
