import React from 'react'
import { Route, Routes } from 'react-router-dom'

import AboutPage from './AboutPage'
import ContactPage from './ContactPage'
import HeaderFooterWrapper from './HeaderFooterWrapper'
import Projects from './Projects'
import { projects } from './ProjectsData'
import ProjectOverview from './ProjectOverview'

import './App.css'

function App() {
  return (
    <Routes>
      <Route path="/" element={<MainPage />}></Route>
      <Route path="about" element={<AboutPage />} />
      <Route path="contact" element={<ContactPage />} />
      <Route
        path="minecraft-legends"
        element={<ProjectOverview project={projects[0]} />}
      />
      <Route path="elder" element={<ProjectOverview project={projects[1]} />} />
      <Route
        path="heat-death"
        element={<ProjectOverview project={projects[2]} />}
      />
      <Route
        path="kitty-pong"
        element={<ProjectOverview project={projects[3]} />}
      />
      <Route
        path="dino-breakers"
        element={<ProjectOverview project={projects[4]} />}
      />
    </Routes>
  )
}

function MainPage() {
  return (
    <HeaderFooterWrapper>
      <div className="title">
        <span className="name">Allie Shackelford</span>
        <div className="titleDivider" />
        <span className="jobTitle">Software Engineer</span>
      </div>
      <div className="content">
        <Projects />
      </div>
    </HeaderFooterWrapper>
  )
}

export default App
