import React from 'react'
import classNames from 'classnames'

import HeaderFooterWrapper from './HeaderFooterWrapper'
import GitLabIcon from './assets/gitlab-icon.svg'
import LinkIcon from './assets/link-icon.png'
import DownloadIcon from './assets/download-icon.jpg'
import { Details, Project, TechOverview } from './ProjectsData'

import './ProjectOverview.css'

interface RoleDisplayProps {
  project: Project
}

function RoleDisplay({ project }: RoleDisplayProps) {
  return (
    <div className="roleDisplay">
      <div className="blockWrapper">
        <div className="roleTextWrapper">
          <div className="borderDecoration" />
          <div className="roleDescription">{project.roleDetails.text}</div>
        </div>
        <TechDetails techOverview={project.techOverview} />
        <div className="linkButtonWrapper">
          {project.repoLink && (
            <a
              className="linkButton"
              href={project.repoLink}
              target={'_blank'}
              rel="noopener noreferrer"
            >
              <img className="repoImg" src={GitLabIcon} />
              <div className="repoText">Repo</div>
            </a>
          )}
          {project.viewLink && (
            <a
              className="linkButton"
              href={project.viewLink}
              target={'_blank'}
              rel="noopener noreferrer"
            >
              <img className="repoImg" src={LinkIcon} />
              <div className="repoText">View</div>
            </a>
          )}
          {project.downloadLink && (
            <a
              className="linkButton"
              href={project.downloadLink.path}
              download={project.downloadLink.downloadFile}
              target={'_blank'}
              rel="noopener noreferrer"
            >
              <img className="repoImg" src={DownloadIcon} />
              <div className="repoText">Download</div>
            </a>
          )}
        </div>
      </div>
      {project.roleDetails.image && (
        <img className="roleImage" src={project.roleDetails.image} />
      )}
    </div>
  )
}

interface TechDetailsProps {
  techOverview: TechOverview[]
}

function TechDetails({ techOverview }: TechDetailsProps) {
  return (
    <div className="projectDetailsWrapper">
      <div className="borderDecoration" />
      {techOverview.map((overview, index) => {
        return (
          <div className="techOverview" key={`overview_${index}`}>
            <div className="smallHeader">{overview.category + ': '}</div>
            <div className="listWrapper">
              <ul>
                {overview.details.map((detail, index) => {
                  return <li key={`${overview.category}_${index}`}>{detail}</li>
                })}
              </ul>
            </div>
          </div>
        )
      })}
    </div>
  )
}

interface ProjectDetailProps {
  index: number
  item: Details
}

function ProjectDetail({ index, item }: ProjectDetailProps) {
  const isEven = index % 2 === 0
  const projectDetailClassnames = classNames('projectDetail', {
    ['right']: isEven
  })

  return (
    <div className={projectDetailClassnames}>
      <div className="projectDetailText">{item.text}</div>
      {item.image && <img className="projectDetailImage" src={item.image} />}
    </div>
  )
}

interface ProjectOverviewProps {
  project: Project
}

export default function ProjectOverview({ project }: ProjectOverviewProps) {
  return (
    <HeaderFooterWrapper>
      <div className="page">
        <div className="projectHeader">
          <div className="projectName">{project.name}</div>
          <div className="projectInfoWrapper">
            <div className="projectInfo">{project.location}</div>
            <div className="projectInfo">{project.timeline}</div>
          </div>
        </div>
        <div className="projectOverview">
          <RoleDisplay project={project} />
        </div>
        <div className="projectDetails">
          {project.projectDetails.map((detail, index) => {
            return (
              <ProjectDetail
                key={`detail_${index}`}
                index={index}
                item={detail}
              />
            )
          })}
        </div>
      </div>
    </HeaderFooterWrapper>
  )
}
