import React from 'react'

import './App.css'

export default function CopyRight() {
  const copyRightNotice = '© All Rights Reserved By Allie Shackelford'
  return (
    <div className="copyRight">
      <div className="copyRightNotice">{copyRightNotice}</div>
    </div>
  )
}
