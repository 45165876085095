import React from 'react'
import { useNavigate } from 'react-router-dom'

import { Project, projects } from './ProjectsData'

import './Projects.css'

interface ProjectCardProps {
  project: Project
}

function ProjectCard({ project }: ProjectCardProps) {
  const navigate = useNavigate()

  return (
    <div className="projectCard" onClick={() => navigate(project.link)}>
      <div className="projectCardBorder" />
      <img
        className="projectCardImage"
        style={{ objectPosition: project.objectPosition }}
        src={project.image}
      />
      <div className="banner">{project.name}</div>
    </div>
  )
}

function Projects() {
  return (
    <div className="projects">
      {projects.map((project, index) => {
        return <ProjectCard key={index} project={project} />
      })}
    </div>
  )
}

export default Projects
