import React from 'react'

import HeaderFooterWrapper from './HeaderFooterWrapper'
import EmailIcon from './assets/email-icon.jpg'
import LinkedInIcon from './assets/linkedin-icon.png'
import GitLabIcon from './assets/gitlab-icon.svg'

import './ContactPage.css'

export default function ContactPage() {
  return (
    <HeaderFooterWrapper>
      <div className="contactPage">
        <div className="contactText">Contact Me</div>
        <div className="contactDetailsWrapper">
          <div className="contactDetail">
            <a
              href="mailto:allieshackelford@gmail.com"
              target={'_blank'}
              rel="noopener noreferrer"
            >
              <img className="contactIcon" src={EmailIcon} />
            </a>
            <div className="contactIconName">Email</div>
          </div>
          <div className="contactDetail">
            <a
              href="https://www.linkedin.com/in/alexandrea-shackelford/"
              target={'_blank'}
              rel="noopener noreferrer"
            >
              <img className="contactIcon" src={LinkedInIcon} />
            </a>
            <div className="contactIconName">LinkedIn</div>
          </div>
          <div className="contactDetail">
            <a
              href="https://gitlab.com/allieshackelford"
              target={'_blank'}
              rel="noopener noreferrer"
            >
              <img className="contactIcon" src={GitLabIcon} />
            </a>
            <div className="contactIconName">GitLab</div>
          </div>
        </div>
      </div>
    </HeaderFooterWrapper>
  )
}
