import React, { ReactNode, useEffect, useState } from 'react'
import classNames from 'classnames'
import { useLocation } from 'react-router-dom'

import CopyRight from './CopyRight'

import './HeaderFooterWrapper.css'

interface HamburgerMenuProps {
  setShowSideMenu: React.Dispatch<React.SetStateAction<boolean>>
}

function HamburgerMenu({ setShowSideMenu }: HamburgerMenuProps) {
  const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation()
    setShowSideMenu((show) => !show)
  }

  return (
    <div className="hamburgerMenu" onClick={onClick}>
      <div className="hamburgerMenuBorder" />
      <div className="hamburgerMenuBorder" />
      <div className="hamburgerMenuBorder" />
    </div>
  )
}

interface HamburgerSideMenuProps {
  showSideMenu: boolean
}

function HamburgerSideMenu({ showSideMenu }: HamburgerSideMenuProps) {
  const sideMenuClassnames = classNames('sideMenu', { ['open']: showSideMenu })

  return (
    <div className={sideMenuClassnames}>
      <div className="sideMenuCloseButton" />
      <a
        className="sideMenuItem"
        href="Resume2022.pdf"
        target={'_blank'}
        rel="noopener noreferrer"
      >
        Resume
      </a>
      <a className="sideMenuItem" href="about">
        About Me
      </a>
      <a className="sideMenuItem" href="contact">
        Contact
      </a>
    </div>
  )
}

interface HeaderFooterWrapperProps {
  children: ReactNode
}

export default function HeaderFooterWrapper({
  children
}: HeaderFooterWrapperProps) {
  const [showSideMenu, setShowSideMenu] = useState(false)

  const location = useLocation()
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [location.pathname])

  const isNotRoot = location.pathname != '/'
  const headerClassnames = classNames('header', { ['showRoot']: isNotRoot })

  return (
    <div className="app" onClick={() => setShowSideMenu(false)}>
      <div className={headerClassnames}>
        {isNotRoot && (
          <div className="menuWrapper">
            <a href="/">Allie Shackelford</a>
          </div>
        )}
        <HamburgerMenu setShowSideMenu={setShowSideMenu} />
        <HamburgerSideMenu showSideMenu={showSideMenu} />
        <div className="menuWrapper fullTextMenu">
          <a
            className="rightMargin"
            href="Resume2022.pdf"
            target={'_blank'}
            rel="noopener noreferrer"
          >
            Resume
          </a>
          <a className="rightMargin" href="about">
            About Me
          </a>
          <a className="rightMargin" href="contact">
            Contact
          </a>
        </div>
      </div>
      {children}
      <CopyRight />
    </div>
  )
}
